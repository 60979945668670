<template>
  <main class="404">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h1>404</h1>
          <h2>Page not found</h2>
          <p>We're sorry, but the page you were looking for doesn't exist.</p>
          <p>Check the address for errors and try again.</p>
          <p>
            <router-link to="/">
              <button class="btn btn-primary">Go to Homepage</button>
            </router-link>
          </p>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {};
</script>

<style>
</style>